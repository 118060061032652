import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["ar"];
const availableLanguages = ["ar", "en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/static/i18n/{{lng}}/{{ns}}.json", // translation file path
    },
    fallbackLng: "en",
    lng: "ar",
    debug: false, // set to true if you're debugging
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // not "espaceValue"
      formatSeparator: ",",
    },

    // You can set useSuspense to true if you want to use React's Suspense
    react: {
      useSuspense: true,
    },
  });

export default i18n;
