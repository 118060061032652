import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { listBookDetails, updateBook } from "../actions/bookActions";
import { BOOK_UPDATE_RESET } from "../constants/bookConstants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./bookEditScreen.css";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function setTitleInDesiredForm(inputTitle) {
  let formattedTitle = inputTitle.trim().replace(/ /g, "-");

  formattedTitle = formattedTitle.replace(/(^[-.]+)|([-.]+$)/g, "");

  return formattedTitle;
}

function BookEditScreen({ match, history }) {
  const { t } = useTranslation();

  const bookId = match.params.id;

  // Add state for the selected book type (PDF or audio)
  const [bookType, setBookType] = useState("pdf"); // Default to PDF

  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [bookFileName, setBookFileName] = useState("");
  const [bookFile, setBookFile] = useState("");
  const [audioFileName, setAudioFileName] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadingPDF, setUploadingPDF] = useState(false);
  const [uploadingAudio, setUploadingAudio] = useState(false);

  const [audioFiles, setAudioFiles] = useState([]);
  const [audioFileNames, setAudioFileNames] = useState([]);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookDetails = useSelector((state) => state.bookDetails);
  const { error, loading, book } = bookDetails;

  const bookUpdate = useSelector((state) => state.bookUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = bookUpdate;

  const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [selectedAudioFileNames, setSelectedAudioFileNames] = useState([]);
  const formattedTitle = setTitleInDesiredForm(
    book?.title + "-" + book?.author
  );
  // const audioFileInputRef = useRef(null);

  useEffect(() => {
    if (!userInfo) {
      history.push("/login/");
    }
    if (successUpdate) {
      dispatch({ type: BOOK_UPDATE_RESET });
      history.push("/profile/");
    } else {
      if (!book?.title || book.id !== Number(bookId)) {
        dispatch(listBookDetails(bookId));
      } else {
        setTitle(book.title);
        setAuthor(book.author);
        setImage(book.image);
        setCategory(book.category);

        if (bookType === "pdf") {
          setBookFile(book.bookFile);
        } else if (bookType === "audio") {
          setAudioFile(book.audioFile);
        }

        // Update the selected audio files
        setSelectedAudioFiles(book.audioFiles || []);
        setSelectedAudioFileNames(
          (book.audioFiles || []).map((audioUrl) => {
            if (audioUrl) {
              const parts = audioUrl.split("/");
              const fileNameWithQuery = parts[parts.length - 1];
              const [fileName] = fileNameWithQuery.split("?");
              return fileName;
            }
            return "";
          })
        );

        setDescription(book.description);
      }
    }
  }, [dispatch, book, bookId, history, successUpdate, userInfo, bookType]);

  const notify = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyDownloaded = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!title || title === "Sample Title") {
      notify(t("Please enter the book title!"));
    } else if (!category || category === "Sample Category") {
      notify(t("Please enter the book Category!"));
    } else if (!author || author === "Sample Author") {
      notify(t("Please enter the book Author!"));
    } else {
      // Conditionally dispatch the update based on the selected book type
      if (bookType === "pdf") {
        dispatch(
          updateBook({
            id: bookId,
            author,
            title,
            image,
            description,
            category,
            bookFile,
          })
        );
      } else if (bookType === "audio") {
        dispatch(
          updateBook({
            id: bookId,
            author,
            title,
            image,
            description,
            category,
            audioFile,
          })
        );
      }
    }
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const hiddenPdfFileInput = React.useRef(null);
  const handlePdfClick = async (e) => {
    e.preventDefault();
    hiddenPdfFileInput.current.click();
  };

  const uploadPdfFileHandler = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const formData = new FormData();

    setBookFileName(e.target.files[0].name);

    formData.append("file", file);
    formData.append("bookId", bookId);
    formData.append("title", title);

    setUploadingPDF(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/books/uploadPdf/",
        formData,
        config
      );

      // Assuming data contains the updated audio files
      // Reorder the audio files with numbers like "audioFile_1", "audioFile_2", etc.
      const reorderedAudioFiles = audioFiles.map((audio, index) => {
        return {
          name: `audioFile_${index + 1}`,
          url: audio.url, // You should adapt this based on your data structure
        };
      });

      // Update the audioFiles state with the reordered audio files
      setAudioFiles(reorderedAudioFiles);

      setBookFile(data);
      setUploadingPDF(false);
      notifyDownloaded(t("File uploaded"));
    } catch (error) {
      setUploadingPDF(false);
    }
  };

  // Add functions for handling audio file upload
  const hiddenAudioFileInput = React.useRef(null);
  const handleAudioClick = (event) => {
    hiddenAudioFileInput.current.click();
  };

  const uploadAudioFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    setAudioFileName(e.target.files[0].name);
    formData.append("audio_files", file);
    formData.append("book_id", bookId);
    formData.append("title", formattedTitle);
    setUploadingAudio(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/books/uploadAudio/",
        formData,
        config
      );

      setSelectedAudioFileNames([...selectedAudioFileNames, data]);
      setAudioFile(data);
      setUploadingAudio(false);
      notifyDownloaded(t("Audio file uploaded"));

      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }

      e.target.value = "";
    } catch (error) {
      setUploadingAudio(false);
    }
  };

  const uploadFileHandler = async (e, getState) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileName = title;
    const formData = new FormData();
    setImageName(e.target.files[0].name);

    formData.append("image", file);
    formData.append("bookId", bookId);
    formData.append("title", title);

    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": fileName.type,
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post("/api/books/upload/", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  // Function to remove audio file
  const removeAudioFile = async (index, fileNameToDelete) => {
    try {
      // Send a DELETE request to your Django API to delete the audio file
      // Replace 'YOUR_DJANGO_API_ENDPOINT' with the actual endpoint in your Django backend

      const headers = {
        "X-CSRFToken": `Bearer ${userInfo.token}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const axios = require("axios");
      const { data } = await axios.delete(
        `/api/books/${bookId}/audio/${fileNameToDelete}/`,
        config
      );

      // Update the local state to remove the deleted audio file
      const updatedFiles = [...selectedAudioFiles];
      updatedFiles.splice(index, 1);

      const updatedFileNames = [...selectedAudioFileNames];
      updatedFileNames.splice(index, 1);

      setSelectedAudioFiles(updatedFiles);
      setSelectedAudioFileNames(updatedFileNames);

      // Optionally, you can show a success message or perform other actions upon successful deletion
    } catch (error) {
      console.error("Error deleting audio file:", error);

      // Handle any error that occurred during the delete operation, e.g., show an error message
      // You can use state to display error messages to the user
      // setErrorState(error.message); // Set error state to display an error message
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedFiles = Array.from(audioFileNames);
    const [reorderedItem] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, reorderedItem);

    setAudioFileNames(reorderedFiles);
  };

  return (
    <>
      <div className="book-edit-screen">
        <ToastContainer />
        <h1 className="text-ar">{t("Edit Book")}</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Form onSubmit={submitHandler}>
              {bookType === "pdf" && (
                <Form.Group controlId="bookFile" className="mb-3">
                  <Form.Label>{t("Book File (PDF)")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={bookFile ? bookFileName : t("Enter PDF File")}
                    value={bookFile || ""}
                    onChange={(e) => setBookFile(e.target.value)}
                  />
                  <Button
                    variant={`btn btn-outline-${bookFile ? "success" : "info"}`}
                    onClick={handlePdfClick}
                  >
                    <i className="fa-solid fa-upload"></i> {t("Upload")}
                  </Button>
                  <input
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    ref={hiddenPdfFileInput}
                    onChange={uploadPdfFileHandler}
                  />
                  {uploadingPDF && <Loader />}
                </Form.Group>
              )}
              {/* {bookType === "audio" && ( */}
              <Form.Group
                controlId="audioFiles"
                className="mb-3 drag-container"
              >
                <Form.Label>{t("Audio Files")}</Form.Label>
                <div className="input-group">
                  <label
                    className={`btn btn-outline-${
                      audioFile ? "success" : "info"
                    }`}
                  >
                    <i className="fa-solid fa-upload"></i> {t("Upload")}
                    <input
                      type="file"
                      name="file"
                      style={{ display: "none" }}
                      onChange={uploadAudioFileHandler}
                    />
                  </label>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="audio-files">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {selectedAudioFileNames.map((fileName, index) => (
                          <Draggable
                            key={fileName} // Use the file name as the key
                            draggableId={fileName}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="draggable-item d-flex align-items-center justify-content-between"
                              >
                                <div>{fileName}</div>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    removeAudioFile(index, fileName)
                                  }
                                  className="remove-button"
                                >
                                  {t("Remove")}
                                </Button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {uploadingAudio && <Loader />}
              </Form.Group>
              <Form.Group controlId="image" className="mb-3">
                <Form.Label>{t("Image")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={imageName ? imageName : t("Enter Image")}
                  value={image ? image : ""}
                  onChange={(e) => setImage(e.target.value)}
                />
                <Button
                  variant={`btn btn-outline-${imageName ? "success" : "info"}`}
                  onClick={handleClick}
                >
                  <i className="fa-solid fa-upload"></i> {t("Upload")}
                </Button>
                <input
                  type="file"
                  name="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={uploadFileHandler}
                />
                {uploading && <Loader />}
              </Form.Group>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  type="title"
                  placeholder={t("Enter Title")}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="author" className="mb-3">
                <Form.Label>{t("Author")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Enter Author")}
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="category" className="mb-3">
                <Form.Label>{t("Category")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Enter category")}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="description" className="mb-3">
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("Enter Description")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <div className="d-grid gap-2">
                <Button type="submit" variant="primary">
                  {t("Update Book")}
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    </>
  );
}
export default BookEditScreen;
