import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { createBook, listMyBooks } from "../actions/bookActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { BOOK_CREATE_RESET } from "../constants/bookConstants";
import ListMyBooks from "../components/ListMyBooks";

function ProfileScreen({ history }) {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const bookCreate = useSelector((state) => state.bookCreate);
  const { success: successCreate, book: createdBook } = bookCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  let keyword = history.location.search;

  useEffect(() => {
    dispatch({ type: BOOK_CREATE_RESET });

    if (!userInfo) {
      history.push("/login/");
    } else {
      if (!user || !user.name || success || userInfo.id !== user.id) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listMyBooks());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }

    if (successCreate) {
      history.push(`/book/${createdBook?.title}/${createdBook.id}/edit/`);
    } else {
      dispatch(listMyBooks(keyword));
    }
  }, [
    dispatch,
    history,
    userInfo,
    user,
    success,
    successCreate,
    createdBook,
    keyword,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user.id,
          name: name,
          email: email,
          password: password,
        })
      );
      setMessage("");
    }
  };
  const createBookHandler = () => {
    dispatch(createBook());
  };

  return (
    <Row>
      <Col md={3}>
        <h2>{t("Profile")}</h2>
        {message && <Message variant="danger">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>{t("Name")}</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email" className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="password" className="mb-3">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("Enter Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="on"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="confirmPassword" className="mb-3">
            <Form.Label>{t("Confirm Password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("Confirm Password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="on"
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary">
            {t("Update")}
          </Button>
        </Form>
      </Col>
      <Col md={9}>
        <Row>
          <Col className="text-right">
            <Button
              variant="primary"
              className="my-3"
              onClick={createBookHandler}
            >
              <i className="fas fa-upload"></i> {t("Upload")}
            </Button>
          </Col>
        </Row>
        <h2>{t("My Books")}</h2>
        <ListMyBooks keyword={keyword} history={history} />
      </Col>
    </Row>
  );
}

export default ProfileScreen;
