import axios from "axios";
import {
  BOOK_CREATE_REQUEST,
  BOOK_CREATE_SECCESS,
  BOOK_CREATE_FAIL,
  BOOK_UPDATE_REQUEST,
  BOOK_UPDATE_SECCESS,
  BOOK_UPDATE_FAIL,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SECCESS,
  BOOK_DETAILS_FAIL,
  BOOK_DELETE_REQUEST,
  BOOK_DELETE_SECCESS,
  BOOK_DELETE_FAIL,
  BOOK_LIST_REQUEST,
  BOOK_LIST_SECCESS,
  BOOK_LIST_FAIL,
  BOOK_LIST_MY_REQUEST,
  BOOK_LIST_MY_SUCCESS,
  BOOK_LIST_MY_FAIL,
} from "../constants/bookConstants";

export const listBooks =
  (keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: BOOK_LIST_REQUEST });

      const { data } = await axios.get(`/api/books${keyword}`);

      dispatch({
        type: BOOK_LIST_SECCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOK_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const bookDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOOK_DETAILS_REQUEST });

    const { data } = await axios.get("/api/books/" + id);

    dispatch({
      type: BOOK_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBookDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOOK_DETAILS_REQUEST });

    const { data } = await axios.get("/api/books/" + id);

    dispatch({
      type: BOOK_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBook = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOK_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const axios = require("axios");
    const { data } = await axios.post(`/api/books/create/`, {}, config);

    dispatch({
      type: BOOK_CREATE_SECCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const addBook = (book) => (dispatch) => {
  dispatch({ type: BOOK_CREATE_REQUEST });

  // Assuming an API call to add the book, for example:
  /*
  api.addBook(book)
      .then(response => {
          dispatch({ type: ADD_BOOK_SUCCESS, payload: response.data });
      })
      .catch(error => {
          dispatch({ type: ADD_BOOK_FAILURE, error: error.message });
      });
  */

  // For the sake of this example, we'll mock a successful book addition:
  setTimeout(() => {
    dispatch({ type: BOOK_CREATE_SECCESS, payload: book });
  }, 1000);
};

export const updateBook = (book) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOK_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.put(
      `/api/books/update/${book.id}/`,
      book,
      config
    );

    dispatch({
      type: BOOK_UPDATE_SECCESS,
      payload: data,
    });

    dispatch({
      type: BOOK_DETAILS_SECCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listMyBooks =
  (keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BOOK_LIST_MY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const axios = require("axios");
      const { data } = await axios.get(`/api/books/mybooks/${keyword}`, config);
      dispatch({
        type: BOOK_LIST_MY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOK_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteBook = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOK_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const axios = require("axios");
    const { data } = await axios.delete(`/api/books/delete/${id}/`, config);

    dispatch({
      type: BOOK_DELETE_SECCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
