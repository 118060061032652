import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { login } from "../actions/userActions";

function LoginScreen({ location, history }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const redirect = location.search
    ? location.search.split("=")[1]
    : "/profile/";

  const userLogin = useSelector((state) => state.userLogin);

  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <FormContainer>
      <h1 className="letterSpacing-ar"> {t("Login")}</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("Enter Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("Enter Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autocomplete="on"
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          {t("Sign In")}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          <p>
            {t("New Customer?")}{" "}
            <Link
              to={redirect ? "/register/?redirect=" + redirect : "/register/"}
            >
              {t("Register")}
            </Link>
          </p>
          {t("Forgot your Password?")}{" "}
          <Link to="/resetPassword/">{t("Reset Password")}</Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

export default LoginScreen;
