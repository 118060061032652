import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";

function RegisterScreen({ location, history }) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);

  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password do not match");
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <FormContainer>
      <h1 className="letterSpacing-ar"> {t("Sign In")}</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            required
            type="name"
            placeholder={t("Name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Label>{t("Email")} </Form.Label>
          <Form.Control
            required
            type="email"
            placeholder={t("Enter Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("Enter Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="mb-3">
          <Form.Label>{t("Confirm Password")}</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          {t("Register")}
        </Button>
      </Form>
      <Row>
        <Col>
          {t("By creating an account, you agree to the")} Kinzbook{" "}
          <Link to={"/terms"}>{t("Terms of Service")} </Link>
          {t("and")} <Link to={"/privacy/"}>{t("Privacy Policy")}</Link>
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          {t("Have an account?")}{" "}
          <Link to={redirect ? "/login/?redirect=" + redirect : "/login/"}>
            {t("Sign In")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

export default RegisterScreen;
