import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { resetPassword } from "../actions/userActions";

function ResetPasswordScreen({ location, history }) {
  const { t } = useTranslation();

  const [requestSent, setRequestSent] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (requestSent) {
      history.push("/");
    }
  }, [history, requestSent]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email));
    setRequestSent(true);
  };

  return (
    <FormContainer>
      <h1 className="letterSpacing-ar"> {t("Request Password Reset")}</h1>
      {/* {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />} */}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("Enter Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button className="btn btn-primary" type="submit">
          {t("Reset Password")}
        </Button>
      </Form>
    </FormContainer>
  );
}

export default ResetPasswordScreen;
