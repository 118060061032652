import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import Message from "../components/Message";
import { bookDetails } from "../actions/bookActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Loader from "../components/Loader";
import { useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { BOOK_DETAILS_RESET } from "../constants/bookConstants";
import { Helmet } from "react-helmet-async";
import AudioPlayer from "../components/audioPlayer/AudioPlayer";
import "react-h5-audio-player/lib/styles.css";
import "./bookScreen.css";
const BookScreen = ({ match, bgcolor }) => {
  const { t, i18n } = useTranslation();
  const [dir, setDir] = useState(i18n.language === "ar" ? "rtl" : "ltr");
  let location = useLocation();

  const dispatch = useDispatch();

  let bookId = match.params.id;
  let { title } = useParams();

  const detailBook = useSelector((state) => state.bookDetails);
  const { error, loading, book } = detailBook;

  const [errorDownloading, setErrorDownloading] = useState(false);
  const [messageDowloading, setMessageDowloading] = useState("");

  const updateCanonicalUrl = (url) => {
    const head = document.querySelector("head");
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      // If a <link rel="canonical"> tag exists, update its href attribute
      canonicalLink.setAttribute("href", url);
    } else {
      // If it doesn't exist, create a new <link rel="canonical"> tag
      const newCanonicalLink = document.createElement("link");
      newCanonicalLink.rel = "canonical";
      newCanonicalLink.href = url;
      head.appendChild(newCanonicalLink);
    }
  };

  const setOgTitle = (title) => {
    const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleMetaTag) {
      ogTitleMetaTag.setAttribute("content", title);
    }
  };

  const setOgDescription = (description) => {
    const ogDescriptionMetaTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionMetaTag) {
      ogDescriptionMetaTag.setAttribute("content", description);
    }
  };

  const setOgUrl = (url) => {
    const ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
    if (ogUrlMetaTag) {
      ogUrlMetaTag.setAttribute("content", url);
    }
  };

  const setOgImage = (url) => {
    const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
    if (ogImageMetaTag) {
      ogImageMetaTag.setAttribute("content", url);
    }
  };

  const image_url = book?.image || "";
  const og_image_url = image_url.replace(/(^\w+:|^)\/\//, "");
  const [isLoadingBookDetails, setIsLoadingBookDetails] = useState(true);

  useEffect(() => {
    if (i18n.language === "ar") {
      setDir("rtl");
    } else {
      setDir("ltr");
    }
  }, [i18n.language]);

  useEffect(() => {
    updateCanonicalUrl(window.location.href);
    setOgUrl(window.location.href);
    setIsLoadingBookDetails(true);
    dispatch({ type: BOOK_DETAILS_RESET });
    dispatch(bookDetails(bookId))
      .then(() => {
        setIsLoadingBookDetails(false);
      })
      .catch((error) => {
        // Handle any errors if necessary
        console.error("Error fetching book details:", error);
        setIsLoadingBookDetails(false);
      });
  }, [bookId, dispatch]);

  useEffect(() => {
    if (!isLoadingBookDetails) {
      if (book?.image) {
        setOgTitle(book.title);
        setOgDescription(book.description);
        setOgImage(`https://${og_image_url}`);
        setOgUrl(window.location.href);
      }
    }
  }, [book, og_image_url, bookId, isLoadingBookDetails]);

  const handlePDFDownload = () => {
    if (book.bookFile) {
      const url = book.bookFile;
      const link = document.createElement("a");
      link.download = `${book.title}.pdf`;
      link.rel = "noopener noreferrer";
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } else {
      setErrorDownloading(true);
      setMessageDowloading(t("File doesn't exist"));
    }
  };

  const style = {
    background: location.bgcolor ? location.bgcolor : "#3A3D3E",
  };

  return (
    <div className="kinzbook__background" dir={dir}>
      <Helmet>
        <link rel="image_src" href={`https://${og_image_url}`} />

        {/* <link
          rel="canonical"
          href={`https://www.kinzbook.com/book/${book?.title}/${bookId}/`}
        /> */}
        <meta property="og:type" content="book" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={book?.title} />

        <meta name="twitter:title" content={book?.title} />
        <meta name="twitter:description" content={book?.description} />
        <meta name="twitter:image" content={`https://${og_image_url}`} />
        <meta name="twitter:image:alt" content={book?.title} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Container className="kinzbook__bookScreenPage">
        {errorDownloading && (
          <Message variant="danger">{t(messageDowloading)}</Message>
        )}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row>
            <Col sm>
              {book.image && book.image !== "/media/images/book.jpeg" ? (
                <img
                  src={book.image}
                  alt={book.title}
                  className="mx-auto d-block kinzbook__bookScreen-image"
                />
              ) : (
                <div className="kinzbook__bookScreen-image-placeholder">
                  <div className="kinzbook__bookScreen-image-placeholder-title">
                    {book.title}
                  </div>
                  <div className="kinzbook__bookScreen-image-placeholder-author">
                    {book.author}
                  </div>
                </div>
              )}

              {book.audioFileUrls && book.audioFileUrls.length > 0 && (
                <div className="kinzbook__audio-players">
                  {book.audioFileUrls.map((audioUrl, index) => (
                    <div className="kinzbook__audio-player" key={index}>
                      {book.audioFileUrls.length > 1 && (
                        <p>{t("Book.Track") + "-" + (index + 1)}</p>
                      )}
                      <AudioPlayer
                        layout="horizontal"
                        autoPlayAfterSrcChange={false}
                        src={audioUrl}
                        showJumpControls={false}
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Col>
            <Col
              xs={12}
              md={6}
              className="kinzbook__bookScreen-details"
              dir={dir}
            >
              <h1 className="kinzbook__bookScreen-title font-weight-bold">
                {book.title}
              </h1>
              <h6 className="kinzbook__bookScreen-author mb-2 " dir={dir}>
                {book.author}
              </h6>
              <hr className="kinzbook__hr"></hr>
              <h6 className="kinzbook__subtitle mb-2">{book.category}</h6>
              <hr className="kinzbook__hr"></hr>
              <h4>{t("Summary")}</h4>
              <p className="kinzbook__bookScreen-description">
                {book.description}
              </p>
              <hr className="kinzbook__hr"></hr>
              {book.audioFile && (
                <>
                  <Button
                    variant="light kinzbook__btn-download"
                    onClick={() => {
                      const audioLink = document.createElement("a");
                      audioLink.href = book.audioFile;
                      audioLink.download = `${book.title}.mp3`;
                      audioLink.click();
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                    {"  "}
                    {t("Download Audio Book")}
                  </Button>
                </>
              )}
              {book.bookFile && (
                <Button
                  variant="light kinzbook__btn-download"
                  onClick={handlePDFDownload}
                >
                  <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                  {"  "}
                  {t("Download PDF")}
                </Button>
              )}
            </Col>
            <Col sm></Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default BookScreen;
