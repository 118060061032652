import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { resetPasswordConfirm } from "../actions/userActions";

function ResetPasswordConfirmScreen({ match, resetPassword, history }) {
  const { t } = useTranslation();

  const [new_password, setNew_password] = useState("");
  const [re_new_password, setRe_new_password] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();

    const uid = match.params.uid;
    const token = match.params.token;
    dispatch(resetPasswordConfirm(uid, token, new_password, re_new_password));
    setRequestSent(true);
  };
  if (requestSent) {
    history.push("/");
  }

  return (
    <FormContainer>
      <h1 className="letterSpacing-ar"> {t("Request Password Reset")}</h1>
      {/* {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />} */}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("New Password")}
            value={new_password}
            onChange={(e) => setNew_password(e.target.value)}
            minLength="6"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>{t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("Confirm Password")}
            value={re_new_password}
            onChange={(e) => setRe_new_password(e.target.value)}
            minLength="6"
            required
          ></Form.Control>
        </Form.Group>

        <Button className="btn btn-primary" type="submit">
          {t("Reset Password")}
        </Button>
      </Form>
    </FormContainer>
  );
}

export default ResetPasswordConfirmScreen;
