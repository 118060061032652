import React, { Suspense } from "react";
import "./App.css";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Header } from "./components";
import { Footer } from "./containers";
import {
  HomeScreen,
  AboutScreen,
  LoginScreen,
  RegisterScreen,
  ResetPasswordScreen,
  ResetPasswordConfirmScreen,
  ProfileScreen,
  BookEditScreen,
  BookScreen,
  TermsScreen,
  PrivacyScreen,
} from "./screens";

function App() {
  return (
    <div className="page-overview">
      <Suspense fallback={null}>
        <Router>
          <Header />
          <div className="wrapper">
            <main className="py-3">
              <Container>
                {/* Public Routes */}
                <Route path="/" exact component={HomeScreen} />
                <Route path="/login/" component={LoginScreen} />
                <Route path="/register/" component={RegisterScreen} />
                <Route path="/about/" component={AboutScreen} />
                <Route path="/terms/" component={TermsScreen} />
                <Route path="/privacy/" component={PrivacyScreen} />

                {/* User Routes */}
                <Route path="/profile/" exact component={ProfileScreen} />

                {/* Password Reset Routes */}
                <Route
                  exact
                  path="/resetPassword"
                  component={ResetPasswordScreen}
                />
                <Route
                  path="/password/reset/confirm/:uid/:token"
                  component={ResetPasswordConfirmScreen}
                />

                {/* Book Routes */}
                <Route path="/book/:title/:id/" exact component={BookScreen} />
                <Route
                  path="/book/:title/:id/edit/"
                  component={BookEditScreen}
                />
              </Container>
            </main>
          </div>
          <Footer />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
