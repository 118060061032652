import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Message from "./Message";
import PaginateMyBook from "../components/PaginateMyBook";
import { listMyBooks, deleteBook } from "../actions/bookActions";

function setTitleInDesiredForm(inputTitle) {
  let formattedTitle = inputTitle.trim().replace(/ /g, "-");

  formattedTitle = formattedTitle.replace(/(^[-.]+)|([-.]+$)/g, "");

  return formattedTitle;
}

function ListMyBooks({ history, keyword }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const bookListMy = useSelector((state) => state.bookListMy);
  const {
    loading: loadingBooks,
    error: errorBooks,
    books,
    page,
    pages,
  } = bookListMy;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [bookId, setBookId] = useState({});
  const msgConfirm = "Are you sure, you want to delete this book?";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(listMyBooks(keyword));
  }, [dispatch, history, userInfo, keyword]);

  const deleteHandler = (id) => {
    dispatch(deleteBook(id));
    dispatch(listMyBooks(keyword));
    setShow(false);
  };
  return (
    <div>
      {loadingBooks ? (
        <Loader />
      ) : errorBooks ? (
        <Message variant="danger">{errorBooks}</Message>
      ) : (
        <div>
          <Table striped bordered hover responsive className="">
            <thead>
              <tr>
                <th>{t("Title")}</th>
                <th>{t("Author")}</th>
                <th>{t("Category")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {books.map((book) => (
                <tr key={book.id}>
                  <td>{book.title}</td>
                  <td>{book.author}</td>
                  <td>{book.category}</td>
                  <td>
                    <LinkContainer
                      to={`/book/${setTitleInDesiredForm(
                        book?.title + "-" + book.author
                      )}/${book.id}/edit/`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit" />
                      </Button>
                    </LinkContainer>

                    {
                      <Button
                        variant="light"
                        className="btn-sm"
                        onClick={() => {
                          setShow(true);
                          setBookId(book.id);
                        }}
                      >
                        <i className="fas fa-trash" id="trashIcon" />
                      </Button>
                    }
                  </td>
                </tr>
              ))}
              <Modal className="dirct-ltr" show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title className="text-ar">
                    {t("Confirm Delete")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{t(msgConfirm)}</Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleClose} variant="light">
                    {t("Close")}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => deleteHandler(bookId)}
                  >
                    {t("Save Changes")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </tbody>
          </Table>
          {<PaginateMyBook pages={pages} page={page} />}
        </div>
      )}{" "}
    </div>
  );
}

export default ListMyBooks;
