import React, { useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BookInfo.css";
import { useTranslation } from "react-i18next";

// import Rating from "./Rating";
const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

function setTitleInDesiredForm(inputTitle) {
  let formattedTitle = inputTitle.trim().replace(/ /g, "-");

  formattedTitle = formattedTitle.replace(/(^[-.]+)|([-.]+$)/g, "");

  return formattedTitle;
}

function BookInfo({ book }) {
  const { t } = useTranslation();
  var colors = [
    "#3A3D3E",
    "#2f4f4f",
    "#704241",
    "#674846",
    "#555d50",
    "#555555",
    "#52593b",
    "#414a4c",
    "#36454f",
    "#444c38",
    "#483c32",
  ];
  var random_color = colors[Math.floor(Math.random() * colors.length)];

  const style = {
    background: random_color,
  };

  const formattedTitle = setTitleInDesiredForm(book?.title + "-" + book.author);
  const formattedUrl = `/book/${formattedTitle}/${book?.id}/`;

  const bookLng = isArabic(book?.title) ? "rtl" : "ltr";

  return (
    <div className="book-container">
      <Link
        to={{
          pathname: formattedUrl,
          bgcolor: random_color,
        }}
        className="rm-text-dec"
        aria-label={book?.title}
      >
        <div className="book-image">
          {book.image && book.image !== "/media/images/book.jpeg" ? (
            <img
              src={book.image}
              alt={book.title}
              className="mx-auto d-block kinzbook__book-image"
            />
          ) : (
            <div className="kinzbook__book-image-placeholder">
              <div
                className="kinzbook__book-image-placeholder-title"
                title={book?.title}
              >
                {book.title}
              </div>
              <div className="kinzbook__book-image-placeholder-author">
                {book.author}
              </div>
            </div>
          )}
        </div>
        <div className="book-info">
          <p className={`book-type langDir--${bookLng}`}>
            {t("Book." + book?.book_type)}
          </p>
          <h3 className={`book-title langDir--${bookLng}`} title={book?.title}>
            {book?.title}
          </h3>
          <p className={`book-author langDir--${bookLng}`}>{book?.author}</p>
          {/* <p className={`book-category   langDir--${bookLng}`}>
            {book?.category}
          </p> */}
        </div>
      </Link>
    </div>
  );
}

export default BookInfo;
