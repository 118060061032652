import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import BookInfo from "../../components/book/BookInfo";
import { listBooks } from "../../actions/bookActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Paginate from "../../components/Paginate";
import Sections from "../../components/Sections";
import { Helmet } from "react-helmet-async";
import "./Home.css";

function HomeScreen({ history }) {
  const dispatch = useDispatch();
  const bookList = useSelector((state) => state.bookList);
  const { error, loading, books, page, pages } = bookList;

  let keyword = history.location.search;

  const setCanonicalUrl = (url) => {
    const head = document.querySelector("head");
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      // If a <link rel="canonical"> tag exists, update its href attribute
      canonicalLink.setAttribute("href", url);
    } else {
      // If it doesn't exist, create a new <link rel="canonical"> tag
      const newCanonicalLink = document.createElement("link");
      newCanonicalLink.rel = "canonical";
      newCanonicalLink.href = url;
      head.appendChild(newCanonicalLink);
    }
  };

  const setOgTitle = (title) => {
    const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleMetaTag) {
      ogTitleMetaTag.setAttribute("content", title);
    }
  };

  const setOgDescription = (description) => {
    const ogDescriptionMetaTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionMetaTag) {
      ogDescriptionMetaTag.setAttribute("content", description);
    }
  };

  const setOgUrl = (url) => {
    const ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
    if (ogUrlMetaTag) {
      ogUrlMetaTag.setAttribute("content", url);
    }
  };

  const setOgImage = (url) => {
    const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
    if (ogImageMetaTag) {
      ogImageMetaTag.setAttribute("content", url);
    }
  };

  useEffect(() => {
    dispatch(listBooks(keyword));

    setCanonicalUrl("https://www.kinzbook.com");
    setOgTitle("Kinzbook.com. أفضل الكتب");
    setOgDescription(
      "PDF مكتبة إلكترونيّة لتحميل  و قراءة جميع أشهر و أروع  الكتب و الكتب المصوّرة على الهواتف الذكية والاجهزة الكفيّة أونلاين و, القرآن الكريم، ورش، حديث صحيح، سنة نبوية، بحث بحوث، المذهب المالكي، إسلام، الغرب الإسلامي، بخاري مسلم"
    );
    setOgUrl("https://www.kinzbook.com");
    setOgImage(
      "https://tallshadowawsbucket.s3.eu-west-3.amazonaws.com/kinzbook/images/kinzbook.png"
    );
  }, [dispatch, keyword]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Helmet>
            {/* <link rel="canonical" href="https://www.kinzbook.com" /> */}
            <meta property="og:type" content="website" />
            {/* <meta property="og:url" content="https://www.kinzbook.com" /> */}
          </Helmet>
          <div className="kinzbook__bookList">
            {books.map((book, index) => (
              <BookInfo key={index} book={book} />
            ))}
          </div>
          <Paginate page={page} pages={pages} keyword={keyword} />

          {/* <Row className="kinzbook__container">
            <Col
              xs={12}
              md={8}
              lg={9}
              className="kinzbook__container--bookList"
            >
              <div className="kinzbook__bookList">
                {books.map((book, index) => (
                  <BookInfo key={index} book={book} />
                ))}
              </div>
              <Paginate page={page} pages={pages} keyword={keyword} />
            </Col>
            <Col xs={12} md={4} lg={3} className="kinzbook__container--rss">
              <Sections />
            </Col>
          </Row> */}
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
