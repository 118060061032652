export const BOOK_CREATE_REQUEST = "BOOK_CREATE_REQUEST";
export const BOOK_CREATE_SECCESS = "BOOK_CREATE_SECCESS";
export const BOOK_CREATE_FAIL = "BOOK_CREATE_FAIL";
export const BOOK_CREATE_RESET = "BOOK_CREATE_RESET";

export const BOOK_LIST_MY_REQUEST = "BOOK_LIST_MY_REQUEST";
export const BOOK_LIST_MY_SUCCESS = "BOOK_LIST_MY_SUCCESS";
export const BOOK_LIST_MY_FAIL = "BOOK_LIST_MY_FAIL";
export const BOOK_LIST_MY_RESET = "BOOK_LIST_MY_RESET";

export const BOOK_UPDATE_REQUEST = "BOOK_UPDATE_REQUEST";
export const BOOK_UPDATE_SECCESS = "BOOK_UPDATE_SECCESS";
export const BOOK_UPDATE_FAIL = "BOOK_UPDATE_FAIL";
export const BOOK_UPDATE_RESET = "BOOK_UPDATE_RESET";

export const BOOK_DETAILS_REQUEST = "BOOK_DETAILS_REQUEST";
export const BOOK_DETAILS_SECCESS = "BOOK_DETAILS_SECCESS";
export const BOOK_DETAILS_FAIL = "BOOK_DETAILS_FAIL";
export const BOOK_DETAILS_RESET = "BOOK_DETAILS_RESET";

export const BOOK_DELETE_REQUEST = "BOOK_DELETE_REQUEST";
export const BOOK_DELETE_SECCESS = "BOOK_DELETE_SECCESS";
export const BOOK_DELETE_FAIL = "BOOK_DELETE_FAIL";
export const BOOK_DELETE_RESET = "BOOK_DELETE_RESET";

export const BOOK_LIST_REQUEST = "BOOK_LIST_REQUEST";
export const BOOK_LIST_SECCESS = "BOOK_LIST_SECCESS";
export const BOOK_LIST_FAIL = "BOOK_LIST_FAIL";
