import React, { useRef, useState, useEffect } from "react";
import H5AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./AudioPlayer.css";

const getNormalizedKey = (url) => {
  try {
    const { hostname, pathname } = new URL(url);
    return `audioPlaybackTime_${hostname}${pathname}`;
  } catch (e) {
    console.error("Error normalizing URL:", e);
    return null;
  }
};

const AudioPlayer = ({ src }) => {
  const audioRef = useRef();
  const [savedTime, setSavedTime] = useState(0); // New state for saved time
  const [isTimeSet, setIsTimeSet] = useState(false);

  // Load the saved time on mount and update the state
  useEffect(() => {
    const savedTimeKey = getNormalizedKey(src);
    const savedTime = localStorage.getItem(savedTimeKey);

    if (savedTime !== null) {
      setSavedTime(parseFloat(savedTime)); // Update state with saved time
    }
  }, [src, savedTime]); // Runs only when src changes

  // Function to update the current time in local storage
  const handleListen = () => {
    const savedTimeKey = getNormalizedKey(src);
    if (savedTimeKey && audioRef.current) {
      localStorage.setItem(
        savedTimeKey,
        audioRef.current.audio.current.currentTime.toString()
      );
    }
  };

  // When the audio can play, set the current time to the saved time
  const handleCanPlay = () => {
    if (audioRef.current && savedTime && !isTimeSet) {
      audioRef.current.audio.current.currentTime = savedTime;
      setIsTimeSet(true);
    }
  };

  const loadSavedTime = (player) => {
    const savedTimeKey = getNormalizedKey(src);
    if (!savedTimeKey) return; // Ensure we have a valid key

    const savedTime = localStorage.getItem(savedTimeKey);
    if (savedTime === null) return; // Ensure there's saved time to load

    const savedTimeFloat = parseFloat(savedTime);
    if (!isFinite(savedTimeFloat)) return; // Ensure it's a finite number

    // Check if the player and audio current are available
    if (player && player.audio && player.audio.current) {
      player.audio.current.currentTime = savedTimeFloat;
      // console.log("Loaded Saved Time:", savedTimeFloat);
    } else {
      console.log("Player or audio not ready");
    }
  };

  return (
    // <H5AudioPlayer
    //   src={src}
    //   onListen={handleListen}
    //   onCanPlay={handleCanPlay}
    //   // ref={audioRef}
    //   customProgressBarSection={[
    //     RHAP_UI.PROGRESS_BAR,
    //     RHAP_UI.CURRENT_TIME,
    //     RHAP_UI.DURATION,
    //   ]}
    //   customControlsSection={[
    //     RHAP_UI.MAIN_CONTROLS, // Correct usage
    //     RHAP_UI.VOLUME_CONTROLS,
    //   ]}
    //   customAdditionalControls={[]}
    // />

    <H5AudioPlayer
      src={src}
      onListen={handleListen}
      onCanPlay={handleCanPlay}
      ref={(elem) => {
        audioRef.current = elem;
        loadSavedTime(elem); // Call loadSavedTime here if you need immediate access
      }}
      customProgressBarSection={[
        RHAP_UI.PROGRESS_BAR,
        RHAP_UI.CURRENT_TIME,
        RHAP_UI.DURATION,
      ]}
      customControlsSection={[
        RHAP_UI.MAIN_CONTROLS, // Correct usage
        RHAP_UI.VOLUME_CONTROLS,
      ]}
      customAdditionalControls={[]}
    />
  );
};

export default AudioPlayer;
