import React from "react";
import { useTranslation } from "react-i18next";

const AboutScreen = () => {
  const { t } = useTranslation();

  const dir = localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr";

  const aboutEng_p1 =
    "Welcome to our website Kinzbook, for finding and sharing books and research! Here, you can search for your favorite titles, authors, or genres, and discover new reads that you might not have come across otherwise. You can also connect with other book enthusiasts and share your thoughts on what you're reading. Whether you're a lifelong reader or just starting to explore the world of books, we hope you'll find something here to love.";

  const aboutEng_p2 =
    "One of the great things about our website is the wide range of books that you can find here. From classic literature to the latest bestsellers, we've got it all. You can browse by genre, such as fiction, non-fiction, romance, or mystery, or search for specific titles or authors. If you're not sure what you're in the mood for, you can check out our recommendations based on what other users are reading.";
  const aboutEng_p3 =
    "Another feature of our website is the ability to connect with other book lovers. You can create a profile and join discussions with like-minded readers in our online community. Share your thoughts on what you're currently reading, get recommendations from others, and discover new titles to add to your reading list. You can even start your own book club and invite friends to join.<br/> In addition to finding and sharing books, our website also offers a range of resources for readers. You can find book reviews and ratings from other users, as well as author interviews and book-themed articles to help you get the most out of your reading experience. We also have a variety of tools and features to help you keep track of what you've read and what you'd like to read next. Whether you're a casual reader or a dedicated bookworm, we hope you'll find something here to help you on your reading journey.";
  const aboutAr_p1 =
    "مرحبًا بكم في موقعنا Kinzbook ، بالإضافة إلى البحث عن الكتب ومشاركتها ، يقدم موقعنا الإلكتروني أيضًا مجموعة من الموارد للقراء. يمكنك العثور على تقييمات الكتب وتقييمات المستخدمين الآخرين ، بالإضافة إلى مقابلات المؤلفين والمقالات المتعلقة بالكتب لمساعدتك في تحقيق أقصى استفادة من تجربة القراءة الخاصة بك. لدينا أيضًا مجموعة متنوعة من الأدوات والميزات لمساعدتك على تتبع ما قرأته وما تود قراءته بعد ذلك. سواء كنت قارئًا عاديًا أو مثقفًا متخصصًا ، نأمل أن تجد شيئًا هنا لمساعدتك في رحلة القراءة الخاصة بك.";

  const aboutAr_p2 =
    " ميزة أخرى لموقعنا هي القدرة على التواصل مع محبي الكتب الآخرين. يمكنك إنشاء ملف تعريف والانضمام إلى المناقشات مع القراء المتشابهين في التفكير في مجتمعنا عبر الإنترنت. شارك بأفكارك حول ما تقرأه حاليًا ، واحصل على توصيات من الآخرين ، واكتشف عناوين جديدة لإضافتها إلى قائمة القراءة الخاصة بك. يمكنك حتى بدء نادي الكتاب الخاص بك ودعوة الأصدقاء للانضمام.";

  const aboutAr_p3 =
    " واحدة من الأشياء الرائعة في موقعنا هي مجموعة واسعة من الكتب التي يمكنك العثور عليها هنا. من الأدب الكلاسيكي إلى أحدث الكتب مبيعًا ، لدينا كل شيء. يمكنك التصفح حسب النوع ، مثل الأدب الخيالي أو الواقعي أو الرومانسية أو الغموض ، أو البحث عن عناوين أو مؤلفين معينين. إذا لم تكن متأكدًا من مزاجك ، يمكنك التحقق من توصياتنا بناءً على ما يقرأه المستخدمون الآخرون.<br/> للعثور على الكتب والأبحاث ومشاركتها! هنا ، يمكنك البحث عن العناوين أو المؤلفين أو الأنواع المفضلة لديك ، واكتشاف قراءات جديدة ربما لم تكن قد صادفتها بطريقة أخرى. يمكنك أيضًا التواصل مع عشاق الكتب الآخرين ومشاركة أفكارك حول ما تقرأه. سواء كنت قارئًا مدى الحياة أو بدأت للتو في استكشاف عالم الكتب ، نأمل أن تجد شيئًا هنا لتحبه.";

  return (
    <div>
      {dir === "ltr" ? (
        <div dir="ltr">
          <h4>{t("Who We Are")}</h4>
          <p>{aboutEng_p1}</p>
          <p>{aboutEng_p2}</p>
          <p>{aboutEng_p3}</p>
        </div>
      ) : (
        <div dir="rtl">
          <h4>{t("Who We Are")}</h4>
          <p>{aboutAr_p1}</p>
          <p>{aboutAr_p2}</p>
          <p>{aboutAr_p3}</p>
        </div>
      )}
    </div>
  );
};

export default AboutScreen;
