import {
  BOOK_CREATE_REQUEST,
  BOOK_CREATE_SECCESS,
  BOOK_CREATE_FAIL,
  BOOK_CREATE_RESET,
  BOOK_UPDATE_REQUEST,
  BOOK_UPDATE_SECCESS,
  BOOK_UPDATE_FAIL,
  BOOK_UPDATE_RESET,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SECCESS,
  BOOK_DETAILS_FAIL,
  BOOK_DETAILS_RESET,
  BOOK_LIST_MY_REQUEST,
  BOOK_LIST_MY_SUCCESS,
  BOOK_LIST_MY_FAIL,
  BOOK_LIST_MY_RESET,
  BOOK_LIST_REQUEST,
  BOOK_LIST_SECCESS,
  BOOK_LIST_FAIL,
  BOOK_DELETE_REQUEST,
  BOOK_DELETE_SECCESS,
  BOOK_DELETE_FAIL,
} from "../constants/bookConstants";

export const bookCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOK_CREATE_REQUEST:
      return { loading: true };

    case BOOK_CREATE_SECCESS:
      return { loading: false, success: true, book: action.payload };

    case BOOK_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const bookDetailsReducer = (
  state = { book: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case BOOK_DETAILS_REQUEST:
      return { loading: true, ...state };

    case BOOK_DETAILS_SECCESS:
      return { loading: false, book: action.payload };

    case BOOK_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_DETAILS_RESET:
      return { book: {} };
    default:
      return state;
  }
};

export const bookUpdateReducer = (state = { book: {} }, action) => {
  switch (action.type) {
    case BOOK_UPDATE_REQUEST:
      return { loading: true };

    case BOOK_UPDATE_SECCESS:
      return { loading: false, success: true, book: action.payload };

    case BOOK_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_UPDATE_RESET:
      return { book: {} };

    default:
      return state;
  }
};

export const bookListReducers = (state = { books: [] }, action) => {
  switch (action.type) {
    case BOOK_LIST_REQUEST:
      return { loading: true, books: [] };

    case BOOK_LIST_SECCESS:
      return {
        loading: false,
        books: action.payload.books,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case BOOK_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
// export const bookListMyReducer = (state = { books: [] }, action) => {
//   switch (action.type) {
//     case BOOK_LIST_MY_REQUEST:
//       return {
//         loading: true,
//       };

//     case BOOK_LIST_MY_SUCCESS:
//       return {
//         loading: false,
//         books: action.payload.books,
//       };

//     case BOOK_LIST_MY_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case BOOK_LIST_MY_RESET:
//       return {
//         books: [],
//       };

//     default:
//       return state;
//   }
// };

export const bookListMyReducer = (state = { books: [] }, action) => {
  switch (action.type) {
    case BOOK_LIST_MY_REQUEST:
      return { loading: true, books: [] };

    case BOOK_LIST_MY_SUCCESS:
      return {
        loading: false,
        books: action.payload.books,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case BOOK_LIST_MY_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_LIST_MY_RESET:
      return {
        books: [],
      };

    default:
      return state;
  }
};

export const bookDeleteReducers = (state = {}, action) => {
  switch (action.type) {
    case BOOK_DELETE_REQUEST:
      return { loading: true };

    case BOOK_DELETE_SECCESS:
      return { loading: false, success: true };

    case BOOK_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
