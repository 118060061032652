import React from "react";
import kinzbookLogo from "../../assets/logokinzbookbt.png";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();

  const dir = localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr";

  return (
    <div className="kinzbook__footer section__padding" dir={dir}>
      <div className="kinzbook__footer-links">
        <div className="kinzbook__footer-links_logo">
          <img src={kinzbookLogo} alt="kinzbook_logo" />
        </div>
        <div className="kinzbook__footer-links_div">
          <h4>{t("Company")}</h4>
          <p>
            <Link to={"/about/"}>{t("About us")}</Link>
          </p>
          <p>
            <Link to={"/terms/"}>{t("Terms of Service")}</Link>{" "}
          </p>
          <p>
            <Link to={"/privacy/"}>{t("Privacy Policy")}</Link>
          </p>
        </div>
        <div className="kinzbook__footer-links_div">
          <h4>{t("Links")}</h4>
          <p>
            <a
              className="responsiveSiteFooter__socialLink"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/kinzbookcom"
            >
              <FaFacebook size="1.5em" />{" "}
            </a>
          </p>
          <p>
            {" "}
            <Link to={"/profile/"}>{t("Upload")}</Link>
          </p>
        </div>

        <div className="kinzbook__footer-links_div" id="contact">
          <h4>{t("Get in touch")}</h4>
          <p>{t("Rabat, Morocco")}</p>
          <p>{t("Contact")}: kinzbook@gmail.com</p>
        </div>
      </div>
      <div className="kinzbook__footer-copyright">
        <p>{t("© 2023 Kinzbook, All rights reserved.")}</p>
      </div>
    </div>
  );
};

export default Footer;
