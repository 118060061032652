import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SearchBox from "./SearchBox"; // Uncomment when you have the SearchBox component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import kinzbook from "../../assets/logokinzbookbt.png";
import "./header.css";
import Dropdown from "react-bootstrap/Dropdown";
import { FiUser } from "react-icons/fi";
import { MdOutlineLogout } from "react-icons/md";

const Menu = ({ closeMenu }) => (
  <>
    <p>
      <Link to={`/`} onClick={closeMenu}>
        {" "}
        {i18next.t("Header.Books")}
      </Link>
    </p>
    <p>
      <Link to={`/`} onClick={closeMenu}>
        {" "}
        {i18next.t("Header.AudioBooks")}
      </Link>
    </p>
    <p>
      <Link to={`/`} onClick={closeMenu}>
        {i18next.t("Header.Podcasts")}
      </Link>
    </p>
  </>
);

const Header = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [toggleMenu, setToggleMenu] = useState(false);
  const dir = i18n.language === "ar" ? "rtl" : "ltr";
  const codeLng = i18next.language;

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const logoutHandler = () => dispatch(logout());

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <header dir={dir} className={`kinzbook__header kinzbook__header--${dir}`}>
      <div className="kinzbook__header-container">
        <div
          className={`kinzbook__navbar-toggle kinzbook__navbar-toggle--${dir}`}
        >
          {toggleMenu ? (
            <RiCloseLine onClick={() => setToggleMenu(false)} />
          ) : (
            <RiMenu3Line onClick={() => setToggleMenu(true)} />
          )}
        </div>
        <div className="kinzbook__navbar-logo">
          <Link to={`/`}>
            <img src={kinzbook} alt="Kinzbook Logo" />
          </Link>
        </div>
        <div className="kinzbook__navbar-search">
          <SearchBox className="kinzbook__search-box" />
        </div>
        <nav className="kinzbook__navbar">
          <div className="kinzbook__navbar-globalnav">
            <div className="kinzbook__navbar-language kinzbook__select-container">
              <select
                className="kinzbook__select"
                value={i18n.language}
                onChange={handleLanguageChange}
              >
                <option value="ar">Ar</option>
                <option value="en">En</option>
              </select>
              <div className="kinzbook__select-arrow"></div>
            </div>
            <div className="kinzbook__navbar-menu">
              <Link to="/profile/" className="kinzbook__button-link">
                <Button className="kinzbook__button">
                  <i className="fas fa-upload kinzbook__button-icon"></i>{" "}
                  {t("Book.Upload")}
                </Button>
              </Link>
            </div>

            {/* <div className="kinzbook__navbar-menu">
              <Link to="/profile/">
                <Button variant="outline-dark" className="kinzbook__button">
                  <i className="fas fa-upload"></i> {t("Book.Upload")}
                </Button>
              </Link>
            </div> */}
            <div className="kinzbook__navbar-controls-userSection">
              {userInfo ? (
                /* User is Logged In */
                <Dropdown>
                  <Dropdown.Toggle
                    className="kinzbook__navbar-controls-userDropdown"
                    variant="primary"
                  >
                    <span className="userIcon">
                      <FiUser />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        to={`/profile/`}
                        className="kinzbook__navbar-controls-dropdownItem"
                      >
                        {userInfo.name} <br />
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={logoutHandler}
                      className="kinzbook__navbar-controls-dropdownItem"
                    >
                      {t("Logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                /* User is Not Logged In */
                // <Link
                //   to={`/login/`}
                //   className="kinzbook__navbar-controls-navLink"
                // >
                //   {t("Login")}
                // </Link>

                <div className="kinzbook__navbar-sign">
                  <button type="button">
                    <Link to={`/login/`}>{t("Sign In")}</Link>
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>
        {/* {toggleMenu && ( */}
        <div
          className={`kinzbook__navbar-collapse ${
            toggleMenu ? "visible" : ""
          } kinzbook__navbar-collapse--${dir}`}
        >
          <div className="kinzbook__navbar-collapse-topBar">
            <div className="kinzbook__navbar-logo">
              <Link to={`/`} onClick={() => setToggleMenu(false)}>
                <img src={kinzbook} alt="Kinzbook Logo" />
              </Link>
            </div>
            <div className="kinzbook__navbar-toggle">
              {toggleMenu ? (
                <RiCloseLine onClick={() => setToggleMenu(false)} />
              ) : (
                <RiMenu3Line onClick={() => setToggleMenu(true)} />
              )}
            </div>
          </div>
          <div className="kinzbook__navbar-toggle-language kinzbook__select-container-toggle">
            <select
              className="kinzbook__select-toggle"
              value={i18n.language}
              onChange={handleLanguageChange}
            >
              <option value="ar">Ar</option>
              <option value="en">En</option>
            </select>
          </div>
          <div className="kinzbook__navbar-collapse-menu">
            <Menu closeMenu={() => setToggleMenu(false)} />
          </div>
          <div className="kinzbook__navbar-toggle-controls-userSection ">
            {userInfo ? (
              /* User is Logged In */
              <Dropdown>
                <Dropdown.Toggle className="kinzbook__navbar-toggle-userDropdown">
                  <span className="userIcon">
                    <FiUser />
                  </span>
                  {t("Header.Hi")} {userInfo.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      to={`/profile/`}
                      className="kinzbook__navbar-controls-dropdownItem"
                      onClick={() => setToggleMenu(false)}
                    >
                      {userInfo.name} <br />
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      logoutHandler();
                      setToggleMenu(false);
                    }}
                    className="kinzbook__navbar-controls-dropdownItem"
                  >
                    {t("Logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              /* User is Not Logged In */
              // <Link
              //   to={`/login/`}
              //   className="kinzbook__navbar-toggle-controls-navLink"
              //   onClick={() => setToggleMenu(false)}
              // >
              //   {t("Login")}
              // </Link>

              <div className="kinzbook__navbar-toggle-controls-navLink">
                <button type="button">
                  <Link to={`/login/`} onClick={() => setToggleMenu(false)}>
                    {t("Sign In")}
                  </Link>
                </button>
              </div>
            )}
          </div>
        </div>
        {/* // )} */}
      </div>
    </header>
  );
};

export default Header;
