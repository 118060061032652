import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SearchBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBox = () => {
  const { t, i18n } = useTranslation();
  const [dir, setDir] = useState(i18n.language === "ar" ? "rtl" : "ltr");
  const [keyword, setKeyword] = useState("");
  let history = useHistory();

  useEffect(() => {
    const changeDir = () => {
      setDir(i18n.language === "ar" ? "rtl" : "ltr");
    };

    i18n.on("languageChanged", changeDir);

    return () => {
      i18n.off("languageChanged", changeDir);
    };
  }, [i18n]);

  const submitHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
    if (keyword.trim()) {
      history.push(`/?keyword=${keyword}&page=1`);
    } else {
      history.push(history.location.pathname);
    }
  };

  return (
    <div className={`searchbox searchbox--${dir}`}>
      <FontAwesomeIcon icon={faSearch} className="searchbox__icon" />
      <input
        type="text"
        placeholder={t("Search Books, AudioBooks")}
        value={keyword}
        onChange={submitHandler}
        className={`searchbox__input searchbox__input--${dir}`}
        dir={dir}
      />
    </div>
  );
};

export default SearchBox;
